import { RootState } from '@app/store'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  constraints: { audio: false, video: false, cameraId: null, microphoneId: null },
  currentRtcRoom: null,
}

const panelsSlice = createSlice({
  name: 'panels',
  initialState,
  reducers: {
    setConstraints: (state, action) => {
      state.constraints = { ...state.constraints, ...action.payload }
    },
    setCurrentRtcRoom: (state, action) => {
      state.currentRtcRoom = action.payload
    },
  },
})

export default panelsSlice.reducer

export const selectConstraints = (state: RootState) => state.panels.constraints
export const selectCurrentRtcRoom = (state: RootState) => state.panels.currentRtcRoom

export const { setConstraints, setCurrentRtcRoom } = panelsSlice.actions
